import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  Toolbar,
  alpha,
  Collapse,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { saveAs } from 'file-saver';

// icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaFilePdf, FaGreaterThan } from 'react-icons/fa6';
import { FiPlusCircle } from 'react-icons/fi';
import { IoEye } from 'react-icons/io5';
import { MdDelete, MdOutlineCopyright } from 'react-icons/md';

// Components
import MainCard from 'ui-component/cards/MainCard';
import Message from 'components/Snackbar/Snackbar';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import PrePrintForm from './PrePrintForm';
import { monthss } from './Fields';
import { MENU_OPEN } from 'store/actions';
import { API, Perks_APIS, Postup_APIS } from 'api/API';
import Axios from 'api/Axios';
import './style.css';
import ReusableMenu from 'components/Menu';
import { PostupFilter, PrePrintListingFilter } from 'store/Data';
import axios from 'axios';
import View_Email_Status from './View_Email_Status';
import EmailScheduler from './EmailScheduler';

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      year: Yup.mixed().required('Year  is required'),
      month: Yup.mixed().required('Month  is required'),
    })
  ),
});

const Postup = ({ ...others }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState({ email_Status: false, scheduler: false });
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteListing, setDeleteListing] = useState(false);
  const [deleteSubListing, setDeleteSubListing] = useState(false);
  const [getFileNames, setFileNames] = useState([]);
  const [rowExpansion, setRowExpansion] = useState(Array(showData.length).fill(false));
  const [MenuChecks, setMenuChecks] = useState([]);
  const [RWO_Type, setRwoType] = useState({
    postup: false,
  });
  const [getValues, setValues] = useState({
    month: '',
    year: '',
    printid: '',
    print_order: '',
    batch_number: '',
  });
  const [printData, setPrintData] = useState();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });
  const [uploadCheck, setUploadCheck] = useState({
    pre_print_mergename_id: '',
    post_order: '',
    merged_name: '',
    batch_number: '',
  });
  const [subListingData, setSubListingData] = useState([]);
  const [assetsData, setassetsData] = useState([]);
  const [uploadAssetsData, setUploadAssetsData] = useState({
    year: '',
    month: '',
    post_order: '',
    post_code: '',
    merged_name: '',
    batch_number: '',
    pre_print_id: '',
    file_name: '',
  });

  const [childRowExpansion, setChildRowExpansion] = useState(
    Array(subListingData.length).fill(false)
  );

  const [uploadAsset, setUploadAsset] = useState(false);
  const [addQR, setAddQR] = useState(false);
  const [deleteSubListing_Data, setDeleteSublisting_Data] = useState({
    pre_print_mergename_id: '',
    post_order: '',
    batch_number: '',
    merged_name: '',
    file_name: '',
  });

  const [childListingData, setChildListingdata] = useState([]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const years = ["2021", "2022", "2023", "2024"];
  const years = ['2024', '2025'];
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);

  const handleRowToggle = (index, prePrintID) => {
    setRowExpansion(prevRowExpansion => {
      setChildRowExpansion(Array(subListingData.length).fill(false));
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChildRowToggle = (index, prePrintID, postOrder) => {
    setChildRowExpansion(prevRowExpansion => {
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const SubListing = async merged_name => {
    try {
      setIsLoading(true);
      const result = await Axios.post(Perks_APIS.Perks_Details, {
        merged_name: merged_name,
      });
      if (result.status === 200) {
        setTimeout(() => {
          setSubListingData(result.data.csvData);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const ChildListing = async file_name => {
    const data = {
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(Perks_APIS.Get_Poster_Codes, data);
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setChildListingdata(result.data.data);
        }, 500);
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const PostupFilterdata = useSelector(state => state.Data.PostupFilter);

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      // console.log(FilterData, "filterDat")
      if (PostupFilterdata && Object.keys(PostupFilterdata).length > 0) {
        await handleRWO(newPage);
      } else {
        await Get_All_listings(newPage);
      }
      // await Get_All_listings(newPage);
    } catch (error) {
      console.log('Error in Pagination', error);
    }
  };

  const handleRWO = async page => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Perks_APIS.Perks_Listing}?page=${page}`,
        PostupFilterdata
      );
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, 'Error');
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response.data.error,
      });
    }
  };

  const Get_All_listings = async page => {
    try {
      setIsLoading(true);
      const response = await Axios.post(`${Perks_APIS.Perks_Listing}?page=${page}`);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Listings');
      setShowData([]);
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: err?.message,
      });
    }
  };

  const handleClick = async (option, merged_name, print_order, batch_number, file_name) => {
    if (option === 'Email List Upload') {
      setOpen1(true);
      setRwoType({ postup: true });
      setValues({
        merged_name: merged_name,
        print_order: print_order,
        batch_number: batch_number,
      });
    }
    if (option === 'Send Campaign') {
      // await SendCampaign(file_name);
      setUploadAssetsData({ ...uploadAssetsData, file_name: file_name });
      setOpen({ scheduler: true });
    }
    if (option === 'View Email Records') {
      navigate('email-list', { state: { file_name: file_name } });
    }
    if (option === 'View Email Status') {
      setUploadAssetsData({ ...uploadAssetsData, file_name: file_name });
      setOpen({ email_Status: true });
    }
  };

  const SendCampaign = async file_name => {
    const data = {
      file_name: file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(Postup_APIS.Send_Campaign, data);
      if (result.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: result?.data?.msg,
          severity: 'success',
        });
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.error,
        severity: 'error',
      });
    }
  };

  const GetRWOFiles = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(Perks_APIS.Perks_File_names);
      if (response.status === 200) {
        setFileNames(response.data.fileNames);
      }
    } catch (err) {
      console.log(err, 'Error while getting Files');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetRWOFiles();
    Get_All_listings(page);
    dispatch({ type: MENU_OPEN, id: 'postup' });
  }, []);

  useEffect(() => {
    setChildListingdata(previousData => previousData);
  }, [childListingData]);

  // console.log(getValues, "RwoType");
  return (
    <MainCard title="Email Campaign">
      {/* <ComingSoon /> */}
      <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          fileName: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // handleRWO(values);

          try {
            if (!values.fileName) {
              setSnackbar({
                open: true,
                severity: 'warning',
                message: 'Please select the File Name',
              });
            } else {
              const data = {
                file_name: values.fileName,
              };
              dispatch(PostupFilter(data));
              setIsLoading(true);
              const response = await Axios.post(`${Perks_APIS.Perks_Listing}?page=${page}`, data);
              if (response.status === 200) {
                // console.log(response.data, "response");
                setShowData(response.data.data);
                setNext(response?.data);
                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log(error, 'Hello I am an Error');
            setIsLoading(false);
            setSnackbar({
              open: true,
              severity: 'error',
              message: error.response.data.error,
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others} autoComplete="off">
            {/*  */}

            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: 'center', marginBottom: 5 }}
              >
                <Grid item xs={0.8}>
                  <Typography variant="subtitle1">Filter by:</Typography>
                </Grid>

                {/* Select File */}
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    // error={Boolean(touched.year && errors.year)}
                    // sx={{ ...theme.typography.customInput }}
                  >
                    <Autocomplete
                      autoHighlight
                      options={getFileNames || []}
                      getOptionLabel={option => option || ''}
                      // key={(option) => option.id}
                      renderInput={params => (
                        <TextField {...params} label="Select File" variant="outlined" />
                      )}
                      name="fileName"
                      value={
                        values.fileName ? getFileNames.find(item => item === values.fileName) : ''
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`fileName`, newValue ? newValue : '');
                      }}
                      noOptionsText="No Results Found"
                    />
                    {touched.fileName && errors.fileName && (
                      <FormHelperText error id="standard-weight-helper-text-year">
                        {errors.fileName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Buttons */}
                <Grid item xs={3} style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: '#15223F',
                      padding: '12px 20px',
                      borderRadius: '8px',
                      color: isSubmitting && 'white',
                    }}
                    // onClick={() => {
                    //   dispatch(ViewAllFilter(undefined));
                    // }}
                  >
                    Filter
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: '#C62828',
                      padding: '12px 20px',
                      borderRadius: '8px',
                      color: isSubmitting && 'white',
                    }}
                    onClick={async () => {
                      setFieldValue(`fileName`, '');
                      setPage(1);
                      Get_All_listings(1);
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>

      <TableContainer component={Paper} style={{ border: '1px solid black' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} className="headings">
                #
              </TableCell>
              <TableCell className="headings">Email Campaign Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData?.length > 0 ? (
              showData?.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: 'center',verticalAlign:'top' }} padding="checkbox">
                        {/* {serialNumber(page, index)} */}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          style={{marginTop:7}}
                          onClick={() => {
                            handleRowToggle(index, item.pre_print_id);
                            setUploadAssetsData({
                              ...uploadAssetsData,
                              month: item.month,
                              year: item.year,
                              merged_name: item.merged_name,
                            });
                          }}
                        >
                          {
                            // listingOpen
                            rowExpansion[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={async () => {
                                  await SubListing(item.merged_name);
                                  // console.log(item.pre_print_mergename_id, 'hello this')
                                  setUploadCheck({
                                    ...uploadCheck,
                                    merged_name: item.merged_name,
                                  });
                                  setUploadAssetsData({...uploadAssetsData, file_name:item.file_name})
                                }}
                              />
                            )
                          }
                        </IconButton>
                      </TableCell>
                      <TableCell className="common">
                        {item?.merged_name}
                        <br />

                        <Collapse in={rowExpansion[index]} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                              Details
                            </Typography>
                            <Table size="small" aria-label="purchases" className="dataTable">
                              <TableHead>
                                <TableRow key={index}>
                                  <TableCell>#</TableCell>
                                  <TableCell>Month/Year</TableCell>
                                  <TableCell>Print order</TableCell>
                                  <TableCell>RWO\Batch Number</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subListingData.length > 0 ? (
                                  subListingData.map((item, index) => (
                                    <>
                                      <TableRow className="child" key={index}>
                                        <TableCell>{serialNumber(page, index)}</TableCell>
                                        <TableCell>{item?.merged_name}</TableCell>
                                        <TableCell component="th" scope="row">
                                          {item?.post_order}
                                        </TableCell>
                                        <TableCell>{item?.batch_number}</TableCell>
                                        <TableCell>
                                          <ReusableMenu
                                            APICall="postup"
                                            data={{
                                              state: {
                                                file_name: item.file_name,
                                              },
                                            }}
                                            setMenuChecks={setMenuChecks}
                                            options={[
                                              'Email List Upload',
                                              'View Email Records',
                                              'View Email Status',
                                              'Send Campaign',
                                            ]}
                                            handleOption={option => {
                                              handleClick(
                                                option,
                                                item?.merged_name,
                                                item?.post_order,
                                                item?.batch_number,
                                                item?.file_name
                                              );
                                            }}
                                            YourSelection={
                                              MenuChecks?.emaillist_upload_status === false
                                                ? [
                                                    // "Email List Upload",
                                                    'Send Campaign',
                                                    // "Email Preview",
                                                    'View Email Records',
                                                    'View Email Status',
                                                  ]
                                                : MenuChecks?.emaillist_upload_status === true
                                                  ? ['Email List Upload']
                                                  : []
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ borderBottom: 0 }} colSpan={5}>
                                          <div className="subchild">
                                            <Collapse
                                              in={childRowExpansion[index]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box sx={{ margin: 1 }}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Sub Details
                                                </Typography>
                                                <div size="small" aria-label="purchases">
                                                  <Table className="dataTable">
                                                    <TableHead>
                                                      <TableRow key={index}>
                                                        <TableCell>Poster code</TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {childListingData.length > 0 ? (
                                                        childListingData.map((item, index) => (
                                                          <TableRow key={index}>
                                                            <TableCell>
                                                              {item.POSTER_CODE_1}
                                                            </TableCell>
                                                          </TableRow>
                                                        ))
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              textAlign: 'center',
                                                            }}
                                                            colSpan={3}
                                                          >
                                                            Record Not Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </div>
                                              </Box>
                                            </Collapse>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                                      Record Not Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        title="Please enter the details"
        open={open1}
        overflowY="auto"
        // height="365px"
        content={
          <>
            <PrePrintForm
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              close={() => setOpen1(false)}
              value={getValues}
              setSnackbar={setSnackbar}
              printData={printData}
              apiCall="No"
              SubListing={SubListing}
              RWO_Type={RWO_Type}
              RowClose={() => setRowExpansion(Array(showData.length).fill(false))}
            />
          </>
        }
      />

      <Popup
        title="View Email Status"
        open={open.email_Status}
        overflowY="auto"
        width="700px"
        // height="365px"
        content={
          <>
            <View_Email_Status
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              uploadAssetsData={uploadAssetsData}
              close={() => setOpen({ email_Status: false })}
            />
          </>
        }
      />

      <Popup
        title="Please Schedule the Email Here"
        open={open.scheduler}
        // overflowY="auto"
        CloseOverflow="none"
        width="500px"
        height="380px"
        content={
          <>
            <EmailScheduler
              close={() => setOpen({ scheduler: false })}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setSnackbar={setSnackbar}
              uploadAssetsData={uploadAssetsData}
            />
          </>
        }
      />

      {showData?.length > 0 && next?.totalPages > 1 && (
        <Pagination
          count={next?.totalPages}
          // count={3}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: '100%',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Postup;
