import React from 'react';
import Popup from './Popup';
import { Box, Button } from '@mui/material';

const ConfirmationAlert = ({ title, open, handle_cancel, handleYes, modalWidth }) => {
  return (
    <>
      <Popup
        title={title}
        open={open}
        CloseOverflow="auto"
        width={modalWidth ? modalWidth : "410px"}
        whiteSpace={"nowrap"}
        content={
          <>
            <Box sx={{ mt: 1, textAlign: 'center' }}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                style={{ background: '#C62828', marginRight: 10 }}
                onClick={handle_cancel}
              >
                Cancel
              </Button>
              <Button
                style={{ background: '#15223F' }}
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleYes}
              >
                Yes
              </Button>
            </Box>
          </>
        }
      />
    </>
  );
};

export default ConfirmationAlert;
