const STAGING = "preview"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "9p8pcb0i6h3lkxfvaeb5jlnbw3ipnwcbi4hlaite7wzj0rhz",
  dev: "9p8pcb0i6h3lkxfvaeb5jlnbw3ipnwcbi4hlaite7wzj0rhz",
  prod: "uz85p64rd3ovocni9cerrcpe0bxz73q6n3x8b7prwquy1cvz",
  preview: "xubqzfzflflgncorippm7dbmtwb68l6z2jq4p56w0oii7zpz",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}