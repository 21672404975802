import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
  IconButton, Tooltip
} from '@mui/material';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';
import { replaceSymbols } from 'functions/Functions';
import { MdOutlineDelete } from 'react-icons/md';
import { useState } from 'react';
import ConfirmationAlert from 'components/ConfirmationAlert';
import { Auto_Tag_APIs } from 'api/API';

const fileFields = [
  { name: 'manual', label: 'Manual Assets' },
  { name: 'ad', label: 'Ad Assets' },
  { name: 'icymi', label: 'ICYMI Assets' },
];

const validationSchema = Yup.object().shape({
  manual: Yup.mixed().required('Manual file is required'),
  ad: Yup.mixed().required('Ad file is required'),
});

const Upload_Assets = ({
  isLoading,
  setIsLoading,
  setSnackbar,
  close,
  uploadAssetsData,
  Axios,
  API,
  ChildListing,
  poster_code_Data,
  setPoster_code_Data,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    file: ""
  });

  const AllValues = {
    manual: '',
    ad: '',
    icymi: '',
  };


  const FileChangeHandler = (fieldName, setFieldValue) => (event) => {
    let files = event.target.files;

    // Initialize a flag to check the uploaded file type matches the field
    let validFileForField = false;

    // Loop through the selected files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && file.name.toLowerCase().endsWith('.pdf')) {
        let poster_code = replaceSymbols(uploadAssetsData.post_code);
        let fileName = `${poster_code}_${uploadAssetsData.month
          .slice(0, 3)
          .toUpperCase()}${uploadAssetsData.year.slice(2, 4)}`;

        // Check if file name contains the poster_code and the expected type
        if (file.name.includes(fileName) && file.name.toLowerCase().includes(fieldName)) {
          validFileForField = true;
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: `File name not matched for ${fieldName.toUpperCase()}. Please upload the correct ${fieldName} file.`,
          });
          event.target.value = '';
          return; // Exit if any file name doesn't match the expected pattern
        }
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Please select PDF files only',
        });
        event.target.value = '';
        setFieldValue(fieldName, []);
        return; // Exit if any file is not a PDF
      }
    }

    // Check if the uploaded files match the field type
    if (validFileForField) {
      setFieldValue(fieldName, files); // Only set files if they match the field type
    } else {
      setSnackbar({
        open: true,
        severity: 'error',
        message: `Please upload the required ${fieldName} file(s).`,
      });
      event.target.value = ''; // Clear input if file type does not match
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    const fileTypes = ['manual', 'ad', 'icymi'];

    // Helper function to capitalize the first letter of a string
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    // Loop through each file type to convert and append to formData
    fileTypes.forEach((type) => {
      Array.from(values[type]).forEach((file) => {
        formData.append(capitalizeFirstLetter(type), file);
      });
    });
    const data = {
      month: uploadAssetsData.month,
      year: uploadAssetsData.year,
      post_order: uploadAssetsData.post_order,
      merged_name: uploadAssetsData.merged_name,
      post_code: uploadAssetsData.post_code,
      batch_number: uploadAssetsData.batch_number,
      file_name: uploadAssetsData.file_name,
      ICYMI_Files: poster_code_Data.data,
    };

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    try {
      setIsLoading(true);
      const result = await Axios.Filepost(API.Upload_PrePrint_Assets, formData);
      if (result.status === 200) {
        // console.log(result.data, "hello I am in");
        ChildListing();
        close();
        setSnackbar({ open: true, message: result.data.msg, severity: 'success' });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response.data.error || error.message,
      });
    }
  };

  const DeleteFile = async () => {
    try {
      setIsLoading(true);
      const res = await Axios.post(Auto_Tag_APIs.delete_upload_asset_file, { url: deleteModal.file });
      if (res.status === 200) {
        setPoster_code_Data((prevState) => {
          const updatedData = prevState.data.filter((url) => {
            return url.url !== deleteModal.file;
          });
          return { ...prevState, data: updatedData };
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error in deleting users", error);
      setIsLoading(false);
    }
  };

  const FileTable = ({ title, files, FileType }) => (
    <>
      {/* <strong>{title} Files</strong> */}
      <br />
      <div>
        <table className="accordion">
          <tr>
            <th>{title} Files</th>
            <th style={{ textAlign: 'center', color: '#c62828' }}>{/* Delete All Files */}</th>
          </tr>
          {files?.data?.map((file, index) => (
            <tr key={index}>
              <td style={{ width: '100%' }}>
                <a href={file} style={{ color: '#0179d9' }} target="_blank">
                  {/* {getFileNameFromUrl(file)} */}
                  {file.wallboardName}
                </a>
              </td>
              <td style={{ textAlign: "center" }}>
                <Tooltip title="Delete">
                  <IconButton onClick={() => setDeleteModal({ show: true, file: file.url })}>
                    <MdOutlineDelete />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );

  return (
    <>
      <Formik
        initialValues={AllValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...others} autoComplete="off">
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {/* Files */}
              {fileFields.map((field) => (
                <Grid item xs={12} key={field.name}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched[field.name] && errors[field.name])}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor={`outlined-adornment-${field.name}`}>
                      {field.label}
                    </InputLabel>
                    <Input
                      id={`outlined-adornment-${field.name}`}
                      type="file"
                      name={field.name}
                      inputProps={{
                        accept: '.pdf',
                        multiple: true, // Allow multiple file selection
                      }}
                      onBlur={handleBlur}
                      onChange={FileChangeHandler(field.name, setFieldValue)}
                      endAdornment={
                        <InputAdornment sx={{ fontSize: 22, marginTop: 2 }} position="end">
                          <FaFilePdf />
                        </InputAdornment>
                      }
                    />
                    {touched[field.name] && errors[field.name] && (
                      <FormHelperText error id={`standard-weight-helper-text-${field.name}`}>
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            {poster_code_Data?.data?.length < 1 ? null : (
              <FileTable title="ICYMI" files={poster_code_Data?.data} FileType="ICYMI" setPoster_code_Data={setPoster_code_Data} />
            )}

            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: '#C62828',
                  marginRight: '10px',
                  color: isLoading === true && '#fff',
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: '#1d213e',
                  marginRight: '15px',
                  color: isLoading === true && '#fff',
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
      {deleteModal.show && <ConfirmationAlert
        title="Are you sure you want to delete this file?"
        open={deleteModal}
        handle_cancel={() => setDeleteModal({ show: false })}
        handleYes={DeleteFile}
      />}
    </>
  );
};

export default Upload_Assets;
