// Icons
import { IconDashboard, IconBrandChrome, IconWindmill } from "@tabler/icons";
import { ReactComponent as DataCollection } from '../assets/images/icons/data-collection.svg';
import { ReactComponent as MetaData } from '../assets/images/icons/meta-data.svg';
import {
  BiBookContent,
  BiSolidNotepad,
  BiGitMerge,
  BiImport,
} from "react-icons/bi";
import { SiFlipboard } from "react-icons/si";
import { HiCursorClick, HiTemplate } from "react-icons/hi";
import { GoProjectTemplate } from "react-icons/go";
import { TiTick } from "react-icons/ti";
import { RxCrossCircled } from "react-icons/rx";
import { BsEye } from "react-icons/bs";
import { GrValidate } from "react-icons/gr";
import { PiFilePdfFill } from "react-icons/pi";
import { FaUser } from "react-icons/fa";
import { RiAiGenerate } from "react-icons/ri";
import { GiNotebook } from "react-icons/gi";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { TfiWrite } from "react-icons/tfi";
import { MdCreateNewFolder, MdOutlineMail } from "react-icons/md";
import { RiPrinterLine } from "react-icons/ri";
import { PiImagesSquareLight } from "react-icons/pi";
import { ImQrcode } from "react-icons/im";


// constant
const icons = {
  IconDashboard,
  IconBrandChrome,
  IconWindmill,
  BiBookContent,
  SiFlipboard,
  HiCursorClick,
  TiTick,
  RxCrossCircled,
  BiSolidNotepad,
  GoProjectTemplate,
  BsEye,
  PiFilePdfFill,
  HiTemplate,
  FaUser,
  RiAiGenerate,
  BiGitMerge,
  GiNotebook,
  FaUsersBetweenLines,
  BiImport,
  TfiWrite,
  MdCreateNewFolder,
  RiPrinterLine, DataCollection ,MetaData,
  IconDashboard,MdOutlineMail, IconBrandChrome,ImQrcode, PiImagesSquareLight, BiBookContent, SiFlipboard, HiCursorClick, TiTick, RxCrossCircled, BiSolidNotepad, GoProjectTemplate, BsEye, PiFilePdfFill, HiTemplate, FaUser, RiAiGenerate,BiGitMerge, GiNotebook, FaUsersBetweenLines, BiImport, TfiWrite, MdCreateNewFolder, GrValidate: () => <GrValidate size={20} />
};

const GetItem = localStorage.getItem("Profile_Details");
const Details = JSON.parse(GetItem);

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard/",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "templatemanagement",
      title: "Template Management",
      type: "collapse",
      icon: icons.HiTemplate,
      children: [
        {
          id: "template-management",
          title: "Predefined Templates",
          type: "item",
          // url: "contentManagement/selecttemplates",
          url: "/template-management/predefined-templates",
          breadcrumbs: false,
          icon: icons.GoProjectTemplate,
        },
      ],
    },

    {
      id: "contentmanagements",
      title: "Content Management",
      type: "collapse",
      icon: icons.BiSolidNotepad,
      children: [
        {
          id: "validate-content",
          title: "Validate Eligible Content",
          type: "item",
          url: "/contentmanagement/validate-eligible-content",
          breadcrumbs: false,
          icon: icons.GrValidate
        },
      ],
    },

    {
      id: "icons",
      title: "Wallboard Management",
      type: "collapse",
      icon: icons.SiFlipboard,
      children: [
        {
          id: "createwallboard",
          title: "Create Wallboard",
          type: "item",
          url: "/selectwallboard/create-wallboard",
          breadcrumbs: false,
          icon: icons.MdCreateNewFolder,
        },
        {
          id: "selectwallboard",
          title: "Wallboard Components",
          type: "item",
          url: "/selectwallboard/pending",
          breadcrumbs: false,
          icon: icons.HiCursorClick,
        },
      ],
    },
    {
      id: "datacollection",
      title: "Data Collection",
      type: "item",
      url: "/data-collection",
      icon: icons.DataCollection,
      breadcrumbs: false,
    },
    {
      id: "metadata",
      title: "Meta Data",
      type: "item",
      url: "/meta-data",
      icon: icons.MetaData,
      breadcrumbs: false,
    },
    {
      id: "preprint",
      title: "Pre Print",
      type: "item",
      url: "/prePrint",
      icon: icons.RiPrinterLine,
      breadcrumbs: false,
    },
    
    
    {
      id: "perks",
      title: "Perks",
      type: "item",
      url: "/perks",
      icon: icons.ImQrcode,
      breadcrumbs: false,
    },
    {
      id: "postup",
      title: "Email Campaign",
      type: "item",
      url: "/postup",
      icon: icons.MdOutlineMail,
      breadcrumbs: false,
    },
    {
      id: "pdfManagement",
      title: "PDF Management",
      type: "collapse",
      icon: icons.PiFilePdfFill,
      children: [
        {
          id: "GeneratePDF",
          title: "Manage PDF",
          type: "item",
          url: "/pdf-management/pending",
          breadcrumbs: false,
          icon: icons.RiAiGenerate,
        },

      ],
    },
    {
      id: "user-Management",
      title: "User Management",
      type: "collapse",
      icon: icons.FaUser,
      children: [
        {
          id: "users",
          title: "Users",
          type: "item",
          url: "/user-management/users",
          breadcrumbs: false,
          icon: icons.FaUsersBetweenLines,
        },
      ],
    },
  ],
};

if (Details && Details.role !== "1") {
  dashboard.children = dashboard.children.filter(
    (item) => item.id !== "user-Management" && dashboard
  );
}

if (Details && Details.role !== "1" && Details && Details.role !== "4") {
  dashboard.children = dashboard.children.filter(
    (item) => item.id !== "preprint" && item.id !== "postup" && item.id !== "perks"   && dashboard
  );
}

export default dashboard;
